import { useForm } from 'react-hook-form'

import {
  If,
  Input,
  Navigation,
  Select,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from '@itau-loans-www/shopping/src/layouts/Proposal'
import { cleanAccents } from 'utils'

import useBankData from './hooks.js'

const BankData = ({ pageContext }) => {
  const proposalContext = useProposal()
  const { product } = proposalContext
  const hasWppBtn = product?.proposal?.productCode !== 'FGTS'
  const {
    leadData: { banking_data }
  } = useLead()

  const initialValues = {
    bank: banking_data?.bank ?? '',
    bank_code: banking_data?.bank_code ?? '',
    branch: banking_data?.branch ?? '',
    account_number: banking_data?.account_number ?? '',
    account_digit: banking_data?.account_digit ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid },
    ...formProps
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })
  const currentBank = formProps.watch('bank')
  const accountMaxLength = currentBank === '341 - BANCO ITAU S.A' ? '7' : '15'
  const {
    isFGTS,
    options,
    onSubmit,
    disabledFields,
    submitLoading,
    getLoading
  } = useBankData(formProps)

  const allDisabled = Object.values(disabledFields).every(
    (disabled) => disabled
  )

  return (
    <ProposalLayout maxSteps={4} pageContext={pageContext}>
      <div className="formalization">
        <Shimmer
          isVisible={getLoading}
          height="fit-content"
          margin="0 0 20px 0"
        >
          <h1 className="formalization__title">
            <If
              condition={!isFGTS}
              renderIf={
                'Confirme os dados da conta onde você recebe seu benefício'
              }
              renderElse={
                'Informe os dados da sua conta onde você deseja receber o empréstimo.'
              }
            />
          </h1>
        </Shimmer>

        <form className="formalization__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="formalization__form-fields">
            <div className="formalization__input-wrapper--full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Nome do banco"
                  name="bank"
                  control={control}
                  options={options}
                  formProps={formProps}
                  disabled={disabledFields.bank || getLoading}
                  filterOptions={({ options, search }) => {
                    return options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }}
                  onChange={(selectedOption) => {
                    formProps.setValue('bank_code', selectedOption.value)
                    formProps.trigger()
                  }}
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--small formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  type="tel"
                  label="Agência"
                  id="branch"
                  name="branch"
                  autoComplete="on"
                  maxLength="4"
                  disabled={disabledFields.branch}
                  validators={{
                    rules: {
                      validate: {
                        onlyNumbers: (value) => /^[0-9*]+$/.test(value),
                        avoidIti: (value) => {
                          // regra de negocio no front:
                          // por enquanto, o banco não aceita banco digital
                          // alguns usuarios acham que iti e itau são a mesma coisa
                          // a agencia 500 é a agencia do iti
                          // assim, o time de negocios pediu para disparar um erro quando o usuario imputar 500
                          const isItauBank =
                            currentBank === '341 - BANCO ITAU S.A' ||
                            currentBank === '652 - ITAÚ UNIBANCO HOLDING BM S.A'
                          const isItiAccount = isItauBank && value === '500'
                          
                          return !isItiAccount
                        }
                      }
                    },
                    messages: {
                      onlyNumbers: 'agência inválida',
                      avoidIti:
                        'no momento não é possível receber em sua conta iti. informe outra conta.'
                    }
                  }}
                  control={control}
                  required
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--small formalization__input-wrapper--mobile-big">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  type="tel"
                  label="Conta corrente"
                  id="account_number"
                  name="account_number"
                  control={control}
                  maxLength={accountMaxLength}
                  disabled={disabledFields.account_number}
                  validators={{
                    rules: {
                      validate: {
                        onlyNumbers: (value) => /^[0-9*]+$/.test(value)
                      }
                    },
                    messages: {
                      onlyNumbers: 'conta inválida'
                    }
                  }}
                  required
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--smallest">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Dígito"
                  id="account_digit"
                  name="account_digit"
                  maxLength="1"
                  control={control}
                  disabled={disabledFields.account_digit}
                  validators={{
                    rules: {
                      validate: {
                        onlyNumbersAndX: (value) => /^[0-9xX]+$/.test(value)
                      }
                    },
                    messages: {
                      onlyNumbersAndX: 'digito inválido'
                    }
                  }}
                  required
                />
              </Shimmer>
            </div>
          </div>
          <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
            <Navigation
              nextStep
              nextButtonType="submit"
              hasWppBtn={hasWppBtn}
              isNextButtonDisabled={!allDisabled && !isValid}
              isNextStepButtonLoading={submitLoading}
              nextButtonId={'formalization-documentation-next-btn'}
              nextButtonText="Continuar proposta"
            />
          </Shimmer>
        </form>
      </div>
    </ProposalLayout>
  )
}

export default BankData
