import auth from '@itau-loans-www/shopping/src/services/http/auth'
import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getBanks = async (product) => {
  // TO DO: entender porque em alguns casos o consig_ncor vem como undefined
  const url = `v2/itau-360-shopping-api/banks/${
    product === 'FGTS' ? '?product_code=FGTS' : '?product_code=consig_ncor'
  }`

  const { data } = await auth({}).then((_http) => _http.get(url))

  return data
}

const putLeadBankingData = async (requestBody) => {
  const formatedBody = {
    account_digit: requestBody.account_digit,
    account_number: requestBody.account_number,
    bank_code: requestBody.bank_code,
    branch: requestBody.branch,
    main_account: requestBody.main_account
  }

  const { data } = await defaultLeadsApiCall({
    endpoint: `banking`,
    method: 'put',
    body: formatedBody
  })

  return data
}

const getLeadBankingData = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: `banking`,
    method: 'get'
  })

  return data
}

export default { getBanks, putLeadBankingData, getLeadBankingData }
